import * as React from 'react';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';

function Home() {
    return (
      <React.Fragment>
        <Typography>Home</Typography>
        <Button variant="contained">Hello World</Button>
      </React.Fragment>
    );
}

export default Home;
